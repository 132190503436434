<template>
  <div>
    <b-sidebar
      :visible="isSidebarActive"
      bg-variant="white"
      sidebar-class="sidebar-lg"
      shadow
      backdrop
      no-header
      right
      @hidden="resetForm()"
      @change="updateIsSidebarActive($event)">
      <template #default="{ hide }">
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">
            Add New Hospital Contact
          </h5>
          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide" />
        </div>
        <ValidationObserver
          #default="{ handleSubmit }"
          ref="HospitalContactAddForm">
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm()">
            <TextFieldInput
              v-model="hospitalContact.name"
              name="Name"
              label="Name"
              :rules="{ required: true }"
              trim />
            <SelectField
              v-model="hospitalContact.type"
              :options="typeOption"
              :rules="{ required: true }"
              name="type"
              form-label="Type"
              label-option="label"
              return-value="value" />
            <TextFieldInput
              v-model="hospitalContact.address"
              name="Address"
              label="Address"
              :rules="{ required: true }"
              trim />
            <AddressField
              v-model="hospitalContact.subDistrict"
              :rules="{ required: true }"
              label="SubDistrict"
              name="SubDistrict"
              type="sub-district"
              @select="onSelectAddress($event)" />
            <AddressField
              v-model="hospitalContact.district"
              :rules="{ required: true }"
              name="District"
              label="District"
              type="district"
              @select="onSelectAddress($event)" />
            <AddressField
              v-model="hospitalContact.province"
              :rules="{ required: true }"
              name="Province"
              label="Province"
              type="province"
              @select="onSelectAddress($event)" />
            <AddressField
              v-model="hospitalContact.zipcode"
              :rules="{ required: true }"
              name="ZipCode"
              label="ZipCode"
              type="postcode"
              @select="onSelectAddress($event)" />
            <TextFieldInput
              v-model="hospitalContact.phoneNo"
              name="Phone No."
              label="Phone No."
              :rules="{ required: true }"
              trim />
            <TextFieldInput
              v-model="hospitalContact.faxNo"
              name="Fax No."
              label="Fax No."
              :rules="{ required: false }"
              trim />
            <TextFieldInput
              v-model="hospitalContact.ind"
              name="Ind"
              label="Ind"
              :rules="{ required: false }"
              trim />
            <TextFieldInput
              v-model="hospitalContact.group"
              name="Group"
              label="Group"
              :rules="{ required: false }"
              trim />
            <TextFieldInput
              v-model="hospitalContact.webSite"
              name="Web Site"
              label="Web Site"
              :rules="{ required: false }"
              trim />
            <TextFieldInput
              v-model="hospitalContact.locationGps"
              name="Location GPS"
              label="Location GPS"
              :rules="{ required: false }"
              trim />
            <TextFieldInput
              v-model="hospitalContact.contactPerson"
              name="Contact Person"
              label="Contact Person"
              :rules="{ required: true }"
              trim />
            <TextFieldInput
              v-model="hospitalContact.department"
              name="Department"
              label="Department"
              :rules="{ required: false }"
              trim />
            <TextFieldInput
              v-model="hospitalContact.email"
              name="Email"
              label="Email"
              :rules="{ required: false }"
              trim />
            <TextFieldInput
              v-model="hospitalContact.contactPhoneNo"
              name="Contact Phone No."
              label="Contact Phone No."
              :rules="{ required: true }"
              trim />
            <DatePicker
              v-model="hospitalContact.activeDate"
              :rules="{ required: false }"
              name="Active Date"
              label="Active Date" />
            <DatePicker
              v-model="hospitalContact.expireDate"
              :rules="{ required: false }"
              name="Expire Date"
              label="Expire Date" />
            <TextAreaInput
              v-model="hospitalContact.promotion"
              :name="`Promotion`"
              :label="`Promotion`" />
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2 btn-submit"
                type="submit">
                Add
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                class="btn-submit"
                variant="outline-secondary"
                @click="hide">
                Cancel
              </b-button>
            </div>
          </b-form>
        </ValidationObserver>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import TextFieldInput from '@/components/Form/TextFieldInput.vue'
import AddressField from '@/components/Form/AddressField.vue'
import SelectField from '@/components/Form/SelectField.vue'
import DatePicker from '@/components/Form/DatePicker.vue'
import TextAreaInput from '@/components/Form/TextAreaInput.vue'

export default {
  components: {
    TextFieldInput,
    SelectField,
    AddressField,
    DatePicker,
    TextAreaInput
  },
  model: {
    prop: 'isSidebarActive',
    event: 'update:is-sidebar-active'
  },
  props: {
    isSidebarActive: {
      type: Boolean,
      default: false
    },
    typeOption: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      initialHospitalContact: {
        name: '',
        type: '',
        address: '',
        subDistrict: '',
        district: '',
        province: '',
        zipcode: '',
        phoneNo: '',
        faxNo: '',
        ind: '',
        group: '',
        webSite: '',
        locationGps: '',
        contactPerson: '',
        department: '',
        email: '',
        contactPhoneNo: '',
        activeDate: '',
        expireDate: '',
        promotion: ''
      },
      hospitalContact: {
        name: '',
        type: '',
        address: '',
        subDistrict: '',
        district: '',
        province: '',
        zipcode: '',
        phoneNo: '',
        faxNo: '',
        ind: '',
        group: '',
        webSite: '',
        locationGps: '',
        contactPerson: '',
        department: '',
        email: '',
        contactPhoneNo: '',
        activeDate: '',
        expireDate: '',
        promotion: ''
      }
    }
  },
  methods: {
    updateIsSidebarActive (val) {
      this.$emit('update:is-sidebar-active', val)
    },
    onSubmit () {
      this.$emit('add-hospital-contact', this.hospitalContact)
    },
    resetForm () {
      this.hospitalContact = { ...this.initialHospitalContact }
      this.$refs.HospitalContactAddForm.reset()
    },
    onSelectAddress (address) {
      this.hospitalContact.subDistrict = address?.subDistrict || ''
      this.hospitalContact.district = address?.district || ''
      this.hospitalContact.province = address?.province || ''
      this.hospitalContact.zipcode = address?.postcode || ''
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
