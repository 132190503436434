<template>
  <b-row class="align-items-end">
    <b-col
      sm="12"
      md="3"
      class="mb-1 mb-sm-0">
      <SearchTextInput
        v-model="searchName"
        label="Name"
        placeholder="Name"
        @keyup.enter.native="fetchFilter()" />
    </b-col>
    <b-col
      sm="12"
      md="3"
      class="mb-1 mb-sm-0">
      <FilterDropdown
        :email.sync="searchType"
        :options="typeOption"
        label="Type"
        placeholder="Type"
        label-option="label"
        return-value="value"
        @input="fetchFilter()" />
    </b-col>
    <b-col
      sm="12"
      md="3"
      class="mb-1 mb-sm-0">
      <SearchTextInput
        v-model="searchAddress"
        label="Address"
        placeholder="Address"
        @keyup.enter.native="fetchFilter()" />
    </b-col>
    <b-col
      sm="12"
      md="3"
      class="mb-1 mb-sm-0">
      <SearchTextInput
        v-model="searchZipcode"
        label="Zipcode"
        placeholder="Zipcode"
        @keyup.enter.native="fetchFilter()" />
    </b-col>
    <b-col
      sm="12"
      md="3"
      class="mb-1 mb-sm-0">
      <SearchTextInput
        v-model="searchSubDistrict"
        label="SubDistrict"
        placeholder="SubDistrict"
        @keyup.enter.native="fetchFilter()" />
    </b-col>
    <b-col
      sm="12"
      md="3"
      class="mb-1 mb-sm-0">
      <SearchTextInput
        v-model="searchDistrict"
        label="District"
        placeholder="District"
        @keyup.enter.native="fetchFilter()" />
    </b-col>
    <b-col
      sm="12"
      md="3"
      class="mb-1 mb-sm-0">
      <SearchTextInput
        v-model="searchProvince"
        label="Province"
        placeholder="Province"
        @keyup.enter.native="fetchFilter()" />
    </b-col>
    <b-col
      sm="12"
      md="3"
      class="mb-1 mb-sm-0">
      <SearchTextInput
        v-model="searchContactPerson"
        label="Contact Person"
        placeholder="Contact Person"
        @keyup.enter.native="fetchFilter()" />
    </b-col>
    <b-col
      sm="12"
      md="3"
      class="mb-1 mb-sm-0">
      <StatusDropdown
        :status-filter.sync="statusFilterValue"
        @change-status-filter="fetchFilter()"
      />
    </b-col>
    <b-col
      sm="12"
      md="3"
      class="mt-1">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        class="w-100"
        variant="primary"
        @click="fetchFilter()">
        <feather-icon
          icon="SearchIcon"
          class="mr-50 mr-md-0 mr-lg-0 mr-xl-50" />
        <span class="align-middle">Search</span>
      </b-button>
    </b-col>
  </b-row>
</template>

<script>
import SearchTextInput from '@/components/Filter/SearchTextInput.vue'
import StatusDropdown from '@/components/Filter/StatusDropdown.vue'
import FilterDropdown from '@/components/Filter/FilterDropdown.vue'

export default {
  components: {
    SearchTextInput,
    FilterDropdown,
    StatusDropdown
  },
  props: {
    filter: {
      type: Object,
      default: () => (
        {
          searchName: '',
          searchType: [],
          searchAddress: '',
          searchZipcode: '',
          searchSubDistrict: '',
          searchDistrict: '',
          searchProvince: '',
          searchContactPerson: ''
        }
      )
    },
    typeOption: {
      type: Array,
      default: () => []
    },
    statusFilter: {
      type: String,
      default: null
    }
  },
  computed: {
    searchName: {
      get () {
        return this.filter.searchName
      },
      set (val) {
        this.$emit('update:search-name', val)
      }
    },
    searchType: {
      get () {
        return this.filter.searchType
      },
      set (val) {
        this.$emit('update:search-type', val)
      }
    },
    searchAddress: {
      get () {
        return this.filter.searchAddress
      },
      set (val) {
        this.$emit('update:search-address', val)
      }
    },
    searchZipcode: {
      get () {
        return this.filter.searchZipcode
      },
      set (val) {
        this.$emit('update:search-zipcode', val)
      }
    },
    searchSubDistrict: {
      get () {
        return this.filter.searchSubDistrict
      },
      set (val) {
        this.$emit('update:search-sub-district', val)
      }
    },
    searchDistrict: {
      get () {
        return this.filter.searchDistrict
      },
      set (val) {
        this.$emit('update:search-district', val)
      }
    },
    searchProvince: {
      get () {
        return this.filter.searchProvince
      },
      set (val) {
        this.$emit('update:search-province', val)
      }
    },
    searchContactPerson: {
      get () {
        return this.filter.searchContactPerson
      },
      set (val) {
        this.$emit('update:search-contact-person', val)
      }
    },
    statusFilterValue: {
      get () {
        return this.statusFilter
      },
      set (val) {
        this.$emit('update:status-filter', val)
      }
    }
  },
  methods: {
    fetchFilter () {
      this.$emit('fetch-filter')
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
