<template>
  <div v-if="isLoaded">
    <DeleteModal
      :is-delete-modal-active.sync="isDeleteModalActive"
      :items="deleteItem"
      @confirm-delete="confirmDelete($event)" />
    <HospitalContactAdd
      ref="hospitalContactAdd"
      :type-option="typeOption"
      :is-sidebar-active.sync="isSidebarActive"
      @add-hospital-contact="addHospitalContact($event)" />
    <HospitalContactDetail
      :is-sidebar-active.sync="isSidebarDetailActive"
      :type-option="typeOption"
      :initial-hospital-contact="initialHospitalContact"
      :selected-item.sync="selectedItem"
      @delete-item="openDeleteModal($event)"
      @edit-hospital-contact="editHospitalContact($event)" />
    <HospitalContactFillter
      :filter.sync="filter"
      :status-filter.sync="filter.status"
      :type-option="typeOption"
      @update:search-name="filter.searchName=$event"
      @update:search-type="filter.searchType=$event"
      @update:search-address="filter.searchAddress=$event"
      @update:search-zipcode="filter.searchZipcode=$event"
      @update:search-sub-district="filter.searchSubDistrict=$event"
      @update:search-district="filter.searchDistrict=$event"
      @update:search-province="filter.searchProvince=$event"
      @update:search-contact-person="filter.searchContactPerson=$event"
      @fetch-filter="fetchFilter()" />
    <b-card class="mt-2">
      <TableTop
        v-model="pagination.limit"
        label="Add Hospital Contact"
        @active-sidebar="activeSidebar()"
        @change="fetchFilter()" />
      <b-table
        :items="items"
        :fields="fields"
        striped
        hover
        responsive
        @sort-changed="sortFilter($event)"
        @row-clicked="rowClick($event)">
        <template #cell(status)="data">
          <b-badge :variant="$statusValue(data.value)">
            {{ data.value | formatStatusData }}
          </b-badge>
        </template>
        <template #cell(name)="data">
          <span class="font-weight-semibold">{{ data.value }}</span>
        </template>
        <template #cell(faxNo)="data">
          <div v-if="data && data.item && data.item.faxNo">
            {{ data.item.faxNo }}
          </div>
          <div v-else>
            -
          </div>
        </template>
        <template #cell(ind)="data">
          <div v-if="data && data.item && data.item.ind">
            {{ data.item.ind }}
          </div>
          <div v-else>
            -
          </div>
        </template>
        <template #cell(group)="data">
          <div v-if="data && data.item && data.item.group">
            {{ data.item.group }}
          </div>
          <div v-else>
            -
          </div>
        </template>
        <template #cell(webSite)="data">
          <div v-if="data && data.item && data.item.webSite">
            {{ data.item.webSite }}
          </div>
          <div v-else>
            -
          </div>
        </template>
        <template #cell(locationGps)="data">
          <div v-if="data && data.item && data.item.locationGps">
            {{ data.item.locationGps }}
          </div>
          <div v-else>
            -
          </div>
        </template>
        <template #cell(department)="data">
          <div v-if="data && data.item && data.item.department">
            {{ data.item.department }}
          </div>
          <div v-else>
            -
          </div>
        </template>
        <template #cell(email)="data">
          <div v-if="data && data.item && data.item.email">
            {{ data.item.email }}
          </div>
          <div v-else>
            -
          </div>
        </template>
        <template #cell(activeDate)="data">
          <div v-if="data && data.item && data.item.activeDate">
            {{ data.item.activeDate }}
          </div>
          <div v-else>
            -
          </div>
        </template>
        <template #cell(expireDate)="data">
          <div v-if="data && data.item && data.item.expireDate">
            {{ data.item.expireDate }}
          </div>
          <div v-else>
            -
          </div>
        </template>
        <template #cell(promotion)="data">
          <div v-if="data && data.item && data.item.promotion">
            {{ data.item.promotion }}
          </div>
          <div v-else>
            -
          </div>
        </template>
      </b-table>
      <b-row>
        <b-col
          cols="12"
          sm="12"
          class="d-flex align-items-center justify-content-center justify-content-sm-end">
          <b-pagination
            v-model="pagination.page"
            :total-rows="pagination.totalDocs"
            :per-page="pagination.limit"
            first-number
            last-number
            class="mb-1 mt-2 mr-1"
            prev-class="prev-item"
            next-class="next-item"
            @change="showCurrentPage($event)">
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18" />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import HospitalContactFillter from '@/components/New/HospitalContact/HospitalContactFillter.vue'
import HospitalContactAdd from '@/components/New/HospitalContact/HospitalContactAdd.vue'
import HospitalContactDetail from '@/components/New/HospitalContact/HospitalContactDetail.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import DeleteModal from '@/components/DeleteModal.vue'
import TableTop from '@/components/TableTop.vue'
import HospitalContactProvider from '@/resources/HospitalContactProvider'

const HospitalContactService = new HospitalContactProvider()

export default {
  components: {
    HospitalContactFillter,
    HospitalContactAdd,
    DeleteModal,
    HospitalContactDetail,
    TableTop
  },
  data () {
    return {
      isLoaded: false,
      typeOption: [
        { label: 'คลินิก', value: 'คลินิก' },
        { label: 'สถานพยาบาล', value: 'สถานพยาบาล' }
      ],
      isSidebarActive: false,
      isSidebarDetailActive: false,
      isDeleteModalActive: false,
      filter: {
        searchName: '',
        searchType: [],
        searchAddress: '',
        searchZipcode: '',
        searchSubDistrict: '',
        searchDistrict: '',
        searchProvince: '',
        searchContactPerson: '',
        status: ''
      },
      pagination: {
        page: 1,
        limit: 10,
        totalDocs: 0
      },
      fields: [
        { key: 'name', label: 'Name', sortable: true },
        { key: 'type', label: 'Type', sortable: true },
        { key: 'address', label: 'Address' },
        { key: 'subDistrict', label: 'SubDistrict' },
        { key: 'district', label: 'District' },
        { key: 'province', label: 'Province' },
        { key: 'zipcode', label: 'Zipcode' },
        { key: 'phoneNo', label: 'Phone No.' },
        { key: 'faxNo', label: 'Fax No.' },
        { key: 'ind', label: 'Ind' },
        { key: 'group', label: 'Group' },
        { key: 'webSite', label: 'Web Site' },
        { key: 'locationGps', label: 'Location GPS' },
        { key: 'contactPerson', label: 'Contact Person' },
        { key: 'department', label: 'Department' },
        { key: 'email', label: 'Email' },
        { key: 'contactPhoneNo', label: 'Contact Phone No.' },
        { key: 'activeDate', label: 'Active Date' },
        { key: 'expireDate', label: 'Expire Date' },
        { key: 'promotion', label: 'Promotion' },
        { key: 'status', label: 'Status' }
      ],
      items: [],
      initialHospitalContact: {},
      selectedItem: {},
      deleteItem: {}
    }
  },
  async created () {
    try {
      await this.getHospitalContact()
    } finally {
      this.isLoaded = true
    }
  },
  methods: {
    fetchFilter () {
      this.setPagination(1)
      this.getHospitalContact()
    },
    openDeleteModal (val) {
      this.deleteItem = { ...val }
      this.isDeleteModalActive = true
    },
    async editHospitalContact (val) {
      try {
        const payload = { ...val }
        delete payload.id
        const { data } = await HospitalContactService.update(val.id, payload)
        await this.getHospitalContact()
        this.setInitialHospitalContactAndSelectItem(data)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Updated Successfully',
            icon: 'EditIcon',
            variant: 'success',
            text: '👋 Hospital Contact has been updated'
          }
        })
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Add Fail',
            icon: 'EditIcon',
            variant: 'danger',
            text: '😞 Hospital Contact has been Failed'
          }
        })
      }
    },
    activeSidebar () {
      this.isSidebarActive = true
    },
    async getHospitalContact () {
      try {
        const page = this.$route?.query?.page || this.pagination?.page || 1
        const limit = this.$route?.query?.limit || this.pagination?.limit || 10
        const data = await HospitalContactService.paginate(
          page,
          limit,
          {
            searchName: this.filter.searchName,
            searchType: this.filter.searchType,
            searchAddress: this.filter.searchAddress,
            searchZipcode: this.filter.searchZipcode,
            searchSubDistrict: this.filter.searchSubDistrict,
            searchDistrict: this.filter.searchDistrict,
            searchProvince: this.filter.searchProvince,
            searchContactPerson: this.filter.searchContactPerson,
            status: this.filter.status
          }
        )
        this.setPagination(data.page, data.totalDocs)
        this.items = [...data.data]
      } catch (err) {
        console.log(err)
      }
    },
    async addHospitalContact (val) {
      try {
        await HospitalContactService.create(val)
        await this.getHospitalContact()
        this.resetFormAndToggleSideBar(this.$refs.hospitalContactAdd)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Add Record Successfully',
            icon: 'EditIcon',
            variant: 'success',
            text: '👋 Hospital Contact has been added'
          }
        })
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Add Fail',
            icon: 'EditIcon',
            variant: 'danger',
            text: '😞 Hospital Contact has been Failed'
          }
        })
      }
    },
    rowClick (val) {
      this.setInitialHospitalContactAndSelectItem(val)
      this.isSidebarDetailActive = true
    },
    setInitialHospitalContactAndSelectItem (val) {
      this.selectedItem = { ...val }
      this.initialHospitalContact = { ...val }
    },
    sortFilter (ctx) {
      console.log(ctx)
    },
    showCurrentPage (page) {
      this.setPagination(page)
      this.getHospitalContact()
    },
    setPagination (page, totalDocs) {
      this.$router.replace(`hospital-contact?page=${page}`)
      this.pagination.totalDocs = totalDocs || this.pagination.totalDocs
      this.pagination.page = page || this.pagination.page
    },
    resetFormAndToggleSideBar (ref) {
      this.isSidebarActive = !this.isSidebarActive
      if (ref && ref.resetForm()) ref.resetForm()
    },
    async confirmDelete (val) {
      try {
        this.isSidebarDetailActive = false
        await HospitalContactService.softDelete(val.ID)
        await this.getHospitalContact()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Deleted Successfully',
            icon: 'EditIcon',
            variant: 'success',
            text: '👋 Hospital Contact has been deleted'
          }
        })
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Deleted Fail',
            icon: 'EditIcon',
            variant: 'danger',
            text: `😞 Hospital Contact has been Failed ${error}`
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.card-body {
  padding: 0;
}
</style>
