<template>
  <b-sidebar
    v-if="isSidebarActive"
    :visible="isSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm()"
    @change="updateIsSidebarActive($event)">
    <template #default="{ hide }">
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Hospital Contact Detail
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide" />
      </div>
      <ValidationObserver
        #default="{ handleSubmit }"
        ref="HospitalContactDetailForm">
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm()">
          <TextFieldInput
            v-model="hospitalContact.name"
            name="Name"
            label="Name"
            :rules="{ required: true }"
            trim />
          <SelectField
            v-model="hospitalContact.type"
            :options="typeOption"
            :rules="{ required: true }"
            name="type"
            form-label="Type"
            label-option="label"
            return-value="value" />
          <TextFieldInput
            v-model="hospitalContact.address"
            name="Address"
            label="Address"
            :rules="{ required: true }"
            trim />
          <AddressField
            v-model="hospitalContact.subDistrict"
            :rules="{ required: true }"
            name="SubDistrict"
            label="SubDistrict"
            type="sub-district"
            @select="onSelectAddress($event)" />
          <AddressField
            v-model="hospitalContact.district"
            :rules="{ required: true }"
            name="District"
            label="District"
            type="district"
            @select="onSelectAddress($event)" />
          <AddressField
            v-model="hospitalContact.province"
            :rules="{ required: true }"
            name="Province"
            label="Province"
            type="province"
            @select="onSelectAddress($event)" />
          <AddressField
            v-model="hospitalContact.zipcode"
            :rules="{ required: true }"
            name="ZipCode"
            label="ZipCode"
            type="postcode"
            @select="onSelectAddress($event)" />
          <TextFieldInput
            v-model="hospitalContact.phoneNo"
            name="Phone No."
            label="Phone No."
            :rules="{ required: true }"
            trim />
          <TextFieldInput
            v-model="hospitalContact.faxNo"
            name="Fax No."
            label="Fax No."
            :rules="{ required: false }"
            trim />
          <TextFieldInput
            v-model="hospitalContact.ind"
            name="Ind"
            label="Ind"
            :rules="{ required: false }"
            trim />
          <TextFieldInput
            v-model="hospitalContact.group"
            name="Group"
            label="Group"
            :rules="{ required: false }"
            trim />
          <TextFieldInput
            v-model="hospitalContact.webSite"
            name="Web Site"
            label="Web Site"
            :rules="{ required: false }"
            trim />
          <TextFieldInput
            v-model="hospitalContact.locationGps"
            name="Location GPS"
            label="Location GPS"
            :rules="{ required: false }"
            trim />
          <TextFieldInput
            v-model="hospitalContact.contactPerson"
            name="Contact Person"
            label="Contact Person"
            :rules="{ required: true }"
            trim />
          <TextFieldInput
            v-model="hospitalContact.department"
            name="Department"
            label="Department"
            :rules="{ required: false }"
            trim />
          <TextFieldInput
            v-model="hospitalContact.email"
            name="Email"
            label="Email"
            :rules="{ required: false }"
            trim />
          <TextFieldInput
            v-model="hospitalContact.contactPhoneNo"
            name="Contact Phone No."
            label="Contact Phone No."
            :rules="{ required: true }"
            trim />
          <DatePicker
            v-model="hospitalContact.activeDate"
            :rules="{ required: false }"
            name="Active Date"
            label="Active Date" />
          <DatePicker
            v-model="hospitalContact.expireDate"
            :rules="{ required: false }"
            name="Expire Date"
            label="Expire Date" />
          <TextAreaInput
            v-model="hospitalContact.promotion"
            :name="`Promotion`"
            :label="`Promotion`" />
          <StatusRadioButton :status.sync="hospitalContact.status" />
          <b-row>
            <b-col>
              Created At : {{ hospitalContact.createdAt | formatDateTimeEn }}
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              Created By : {{ hospitalContact.createdBy.username }}
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              Updated At : {{ hospitalContact.updatedAt | formatDateTimeEn }}
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              Updated By : {{ hospitalContact.updatedBy.username }}
            </b-col>
          </b-row>
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2 btn-submit"
              type="submit"
              :disabled="compareOldValue">
              Save
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              class="btn-submit"
              variant="outline-secondary"
              :disabled="compareOldValue"
              @click="resetForm()">
              Cancel
            </b-button>
            <b-button
              variant="outline-danger"
              class="btn-icon ml-auto"
              :disabled="!compareOldValue"
              @click="deleteItem ()">
              <feather-icon icon="Trash2Icon" />
            </b-button>
          </div>
        </b-form>
      </ValidationObserver>
    </template>
  </b-sidebar>
</template>

<script>
import TextFieldInput from '@/components/Form/TextFieldInput.vue'
import AddressField from '@/components/Form/AddressField.vue'
import SelectField from '@/components/Form/SelectField.vue'
import DatePicker from '@/components/Form/DatePicker.vue'
import TextAreaInput from '@/components/Form/TextAreaInput.vue'
import StatusRadioButton from '@/components/Form/StatusRadioButton.vue'

export default {
  components: {
    TextFieldInput,
    SelectField,
    AddressField,
    DatePicker,
    TextAreaInput,
    StatusRadioButton
  },
  model: {
    prop: 'isSidebarActive',
    event: 'update:is-sidebar-active'
  },
  props: {
    isSidebarActive: {
      type: Boolean,
      default: false
    },
    initialHospitalContact: {
      type: Object,
      required: true
    },
    selectedItem: {
      type: Object,
      required: true
    },
    typeOption: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
    }
  },
  computed: {
    hospitalContact: {
      get () {
        return this.selectedItem
      },
      set (val) {
        this.$emit('update:selected-item', val)
      }
    },
    compareOldValue () {
      return JSON.stringify(this.hospitalContact) === JSON.stringify(this.initialHospitalContact)
    }
  },
  methods: {
    updateIsSidebarActive (val) {
      this.$emit('update:is-sidebar-active', val)
    },
    onSubmit () {
      const payload = {
        id: this.hospitalContact.id,
        name: this.hospitalContact.name,
        type: this.hospitalContact.type,
        address: this.hospitalContact.address,
        subDistrict: this.hospitalContact.subDistrict,
        district: this.hospitalContact.district,
        province: this.hospitalContact.province,
        zipcode: this.hospitalContact.zipcode,
        phoneNo: this.hospitalContact.phoneNo,
        faxNo: this.hospitalContact.faxNo,
        ind: this.hospitalContact.ind,
        group: this.hospitalContact.group,
        webSite: this.hospitalContact.webSite,
        locationGps: this.hospitalContact.locationGps,
        contactPerson: this.hospitalContact.contactPerson,
        department: this.hospitalContact.department,
        email: this.hospitalContact.email,
        contactPhoneNo: this.hospitalContact.contactPhoneNo,
        activeDate: this.hospitalContact.activeDate,
        expireDate: this.hospitalContact.expireDate,
        promotion: this.hospitalContact.promotion,
        status: this.hospitalContact.status
      }
      this.$emit('edit-hospital-contact', payload)
    },
    resetForm () {
      this.hospitalContact = { ...this.initialHospitalContact }
      this.$refs.HospitalContactDetailForm.reset()
    },
    deleteItem () {
      this.$emit('delete-item', {
        ID: this.hospitalContact.id,
        Name: this.hospitalContact.name
      })
    },
    onSelectAddress (address) {
      this.hospitalContact.subDistrict = address?.subDistrict || ''
      this.hospitalContact.district = address?.district || ''
      this.hospitalContact.province = address?.province || ''
      this.hospitalContact.zipcode = address?.postcode || ''
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
